/* eslint-disable */
import React, { useState, Fragment, useEffect } from "react"
import { Tooltip } from 'antd'
import { FormattedMessage } from 'react-intl'
import Icon from '../../components/iconfontCpt'

// 文档目录菜单
const DocDirectoryMenu = ({ tableOfContents, pageContext }) => {
  const { slug, relativePath, realSlug } = pageContext
  const docMenu = tableOfContents.replace(/\/#/g, '#')
  const issueHref = `https://gitee.com/enmotech/mogdb-docs/issues/new?&description=原始文件：[/${relativePath}](https://docs.mogdb.io${realSlug || slug})`

  const [scrollTop, setScrollTop] = useState(0)
  useEffect(() => {
    // 获取当前滚动的高度
    const scrollTopVal = document.documentElement.scrollTop || document.body.scrollTop
    setScrollTop(scrollTopVal)
    window.addEventListener("scroll", scrollListenerFunc)

    return () => {
      // 移除事件
      window.removeEventListener("scroll", scrollListenerFunc)
    }
  }, []);

  function scrollListenerFunc (e) {
    const scrollTopVal = document.documentElement.scrollTop || document.body.scrollTop
    setScrollTop(scrollTopVal)
  };

  function scrollToTop () {
    try {
      window.scroll({
        behavior: 'smooth',
        top: 0,
      })
    } catch(err) {
      if (err instanceof TypeError) {
        window.scroll(0, 0)
      } else {
        throw err
      }
    }
  }

  

  // 控制文档目录菜单是否展示
  const [docDirectoryMenuVisible, setDocDirectoryMenuVisible] = useState(false)
  return (
    <Fragment>
      {docDirectoryMenuVisible &&
        <div className="right-container">
          <section className="doc-directory-box">
            <div className="hide-directory-btn" onClick={() => setDocDirectoryMenuVisible(false)}>
              <Icon type="d_navigation" size={16}/>
            </div>
            <div className="directory-content">
              <div dangerouslySetInnerHTML={{ __html: docMenu }} />
            </div>
          </section>
        </div>}
      
      <div className="doc-right-btns">
        <a className="doc-directory-btn issue" target="_blank" href={issueHref} rel="noreferrer">
          <Icon type="d_feedback" size={20}/><br/>
          <FormattedMessage id="pageMenu.issue"/>
        </a>
        {!docDirectoryMenuVisible &&
          <Tooltip placement="left" title={<FormattedMessage id="pageMenu.nav"/>}>
            <div className="doc-directory-btn nav" onClick={() => setDocDirectoryMenuVisible(true)}>
              <Icon type="d_navigation" size={22}/>
            </div>
          </Tooltip>
        }
        {scrollTop >= 400 && 
          <Tooltip placement="left" title={<FormattedMessage id="pageMenu.backtop"/>}>
            <div className="doc-directory-btn backtop" onClick={() => scrollToTop()}>
              <Icon type="d_back" size={22}/>
            </div>
          </Tooltip>
        }
      </div> 
    </Fragment>
  )
}
export default DocDirectoryMenu
