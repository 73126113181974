import React, { useRef, useLayoutEffect } from "react"

const Customgroup = ({ label, children, pageContext }) => {
  const { lang, docType: curProduct, version: curVersion } = pageContext
  const contentRef = useRef(null);
  useLayoutEffect(() => {
    if (!contentRef.current) return;
    const aNode = contentRef.current.querySelectorAll('a');
    aNode.forEach(a => {
      let href = a.href
      // 更改href，规则：根据docs-判断是否为其他产品link，是则进行特殊处理，否则安装当前产品类型、版本进行拼接
      const otherProductRegex = /.*\/docs-(\w+(-\w+)?)\/(v\d+.\d+)\/(.*\/|)([^.]+).md/
      const matchArr = href.match(otherProductRegex)
      if (matchArr && matchArr.length > 0) {
        const [, product, , version, , fileName] = matchArr
        href = `/${lang}/${product}/${version}/${fileName}`
      } else {
        const fileNameRegex = /.*\/([^.]+).md/
        const [, fileName] = href.match(fileNameRegex) || []
        href = fileName ? `/${lang}/${curProduct}/${curVersion}/${fileName}` : href
      }
      a.setAttribute('href', href)
      a.setAttribute('target', '_blank')
    })
  });

  return (
    <div className={`index-group ${lang}`}>
      <h3>{label}</h3>
      <div className="links" ref={contentRef}>{ children }</div>
    </div>
  )
}
export default Customgroup
