// 检查URL是否为内部链接
export function checkInternalLink(link) {
  try {
    // http|https开头
    if (link.startsWith('http')) {
      return false;
    }

    // 斜线或者点开头
    if (link.startsWith('/') || link.startsWith('.')) {
      return true;
    }

    // 其他情况，如：xxx.md xxx/xxx/xxx.md
    const res = link.split('/')[0].split('?')[0];
    return res ? (res.includes('.') ? res.includes('.md') : true) : true;
  } catch (error) {
    return true;
  }
}