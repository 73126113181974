import * as React from "react"
import { Select } from 'antd'
import { navigate } from "gatsby"
import { allProductVersions } from '../../utils/version_config'

const { Option } = Select

// 版本切换
const ProductVersion = ({ pageContext }) => {
  const { lang, version, docType } = pageContext || {}

  let productVersions = (allProductVersions[docType] || {}).versions
  productVersions = Object.values(productVersions).filter(v => !v.disabled)

  function handleChange(value) {
    const url = `/${lang}/${docType}/${value}/overview`
    navigate(url)
  }

  return (
    <Select className="product-version-box" defaultValue={version} onChange={handleChange}>
      {productVersions.map((item, idx) => <Option key={idx} className="product-version-item" value={item.value} >{item.label}</Option>)}
    </Select>
  )
}
export default ProductVersion
