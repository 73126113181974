import * as React from "react"
import { FormattedMessage } from 'react-intl'

import { allProductVersions } from '../../utils/version_config'
import { allProductLabels } from '../../utils/menu_config'
import Icon from '../../components/iconfontCpt'

// 文档顶部版本切换菜单
const DocVersionMenu = ({ pageContext, supportNodes }) => {
  const { lang, version, docType, fileName } = pageContext || {}

  // 版本排序
  function formatVersionToSort(v) {
    let formatV = parseInt(v.replace('v', '').replace(/\./g, ''))
    formatV = isNaN(formatV) ? 0 : ((docType === 'mogdb' && formatV < 100) ? formatV * 10 : formatV) // 处理mogdb部分版本少一位的情况
    return formatV
  }

  // 获取当前产品所有可用的版本号
  const productVersions = (allProductVersions[docType] || {}).versions
  const latestVersion = (allProductVersions[docType] || {}).latestVersion
  const allVersionKeys = Object.keys(productVersions).filter(i => !productVersions[i].disabled).sort((a, b) => formatVersionToSort(b) - formatVersionToSort(a))

  const supportArr = []
  const otherArr = []

  allVersionKeys.forEach((i, idx) => {
    const versionObj = productVersions[i] || {}
    const item = {
      path: `/${lang}/${docType}/${i}/`,
      label: versionObj.value === latestVersion ? `${lang === 'en' ? 'Current' : '最新'}(${versionObj.label || i})` : versionObj.label || i,
      key: i,
      isCurrent: idx === 0,
      isActive: i === version
    }
    const supportItem = supportNodes.find(sItem => sItem.fields.version === i)
    if (supportItem) {
      item.path = supportItem.fields.slug
      supportArr.push(item)
    } else {
      otherArr.push(item)
    }
  })
  supportArr.sort((a, b) => formatVersionToSort(b.key) - formatVersionToSort(a.key))
  otherArr.sort((a, b) => formatVersionToSort(b.key) - formatVersionToSort(a.key))

  return (
    <div className="doc-version-menu">
      {fileName === 'overview' &&  
        <div className="current-product-info">
          <div className={`${docType} w-product-icon`}></div>
          <div>
            <div className="product-type">{ allProductLabels[docType].label }</div>
            <div className="product-desc">{ allProductLabels[docType][lang] }</div>
          </div>
        </div>
      }
      
      <div className="version-menu-item"><Icon type="d_jump" size={14}/><br/><span className="label"><FormattedMessage id="doc.docMenu" />:</span><a className="version" href={`/${lang}/${docType}/${version}/`}>{productVersions[version] && productVersions[version].label}</a></div>
      
      <div className="version-menu-item">
        <Icon type="d_jump" size={14}/><br/>
        <span className="label"><FormattedMessage id="doc.supportVersion" />:</span>
        {supportArr.map((i, idx) =>
          <div key={idx} className="version-item">
            <a className={`version ${i.isActive ? 'is-active' : ''}`} href={i.path}>{i.label}</a>
            {idx + 1 < supportArr.length && <span className="divide">/</span>}
          </div>
        )}
      </div>
      {otherArr.length > 0 &&
        <div className="version-menu-item">
          <Icon type="d_jump" size={14}/><br/>
          <span className="label"><FormattedMessage id="doc.otherVersion" />:</span>
          {otherArr.map((i, idx) =>
            <div key={idx} className="version-item">
              <a className={`version ${i.isActive ? 'is-active' : ''}`} href={i.path}>{i.label}</a>
              {idx + 1 < otherArr.length && <span className="divide">/</span>}
            </div>
          )}
        </div>
      }
    </div>
  )
}
export default DocVersionMenu
