/* eslint-disable */
import '../styles/doc.scss'
import '../styles/productIndex.scss'

import React, { useRef, useLayoutEffect } from "react"
import ReactDOM from "react-dom"
import parse, { domToReact } from 'html-react-parser'
import { graphql } from "gatsby"
import { FormattedMessage } from 'react-intl'

import Copy from 'copy-to-clipboard';

import Layout from "../components/layout"
import Seo from "../components/seo"
import SyntaxDiagram from "../components/syntaxDiagram"
import Icon from '../components/iconfontCpt'

import ProductVersion from './components/productVersion'
import DocToc from './components/docToc'
import DocVersionMenu from "./components/docVersionMenu"
import DocPageMenu from './components/docPageMenu'
import DocDirectoryMenu from './components/docDirectoryMenu'

import CustomGroup from './indexComponents/Customgroup'
import CustomContainer from './indexComponents/CustomContainer'

import { checkInternalLink } from '../utils/methods'  

const DocTmptPage = ({ data, pageContext }) => {
  const { frontmatter, html, tableOfContents } = data.docNode
  const { lang, diagramTree, realSlug, fileName } = pageContext

  const options = {
    replace: (domNode) => {
      const attribs = domNode.attribs
      if (domNode.name === "customcontainer") {
        return <CustomContainer title={attribs.title} subTitle={attribs.subtitle} children={domToReact(domNode.children, options)} pageContext={pageContext} />;
      }
      if (domNode.name === "customgroup") {
        return <CustomGroup pageContext={pageContext} label={attribs.label} children={domToReact(domNode.children, options)} />;
      }
    }
  };

  const domRef = useRef(null);
  useLayoutEffect(() => {
    if (!domRef.current) return;

    // 解析视频
    const asciinemaNodes = document.querySelectorAll('.asciinema-player')
    const asciinemaOptions = {
      theme: 'monokai', preload: true, poster: 'npt:0:1',
      fit: "none", rows: 33
    }
    if (AsciinemaPlayer) {
      asciinemaNodes.forEach(node => {
        const castFilName = node.getAttribute('data-cast')
        AsciinemaPlayer.create(
          `/cast/${castFilName}.cast`,
          node,
          asciinemaOptions
        );
      })
    }

    // 语法树解析
    const domNodes = domRef.current.querySelectorAll('div[data-language="ebnf+diagram"') || [];
    if (domNodes && diagramTree && domNodes.length === diagramTree.length) {
      domNodes.forEach((node, idx) => {
        ReactDOM.render(
          <SyntaxDiagram lang={lang} diagramTree={diagramTree[idx].html} code={diagramTree[idx].node.value} />,
          node
        );
      })
    }

    // 为所有的code增加复制功能
    const preDoms = domRef.current.querySelectorAll('.gatsby-highlight');
    preDoms.forEach(i => {
      if (i.getAttribute('data-language') === 'ebnf+diagram') return
      addCopyBox(i)
    })

    
    // 替换a标签链接
    const aNode = domRef.current.querySelectorAll('a');
    aNode.forEach(a => {
      const hrefVal = a.getAttribute('href')
      if (!hrefVal) return
      // 内部链接，且存在.md 处理URL
      if (checkInternalLink(hrefVal) && hrefVal.includes('.md')) {
        const fileName = hrefVal.substr(hrefVal.lastIndexOf('/') + 1, hrefVal.length).replace('.md', '')
        a.setAttribute('href', fileName)
      }
    })
  });

  function addCopyBox(i) {
    // 判断是否已经存在组件
    if (i.querySelector('.pre-copy-btn')) return
    let btnBox = document.createElement('div')
    btnBox.classList.add('pre-copy-btn')
    btnBox.innerHTML = 'Copy';
    btnBox.addEventListener('click', (e) => {
      Copy(i.querySelector('code').innerText);
      const tipTxt = document.createElement('div');
      tipTxt.style.top = (e.pageY - 30) + 'px';
      tipTxt.style.left = (e.pageX - 20) + 'px';
      tipTxt.classList.add('animate__animated', 'animate__fadeOutUp', 'pre-copied-tip');
      tipTxt.innerHTML = 'Copied';
      tipTxt.addEventListener('animationend', () => {
        document.body.removeChild(tipTxt);
      })
      document.body.appendChild(tipTxt);
    });
    i.appendChild(btnBox)
  }

  const handleShowDocMenu = (e) => {
    const docMenu = e.target
    docMenu.classList.toggle('active')
    const toc = document.querySelector('.toc-container')
    if (toc) toc.classList.toggle('show')
  }

  return (
    <Layout pageContext={pageContext}>
      <Seo
        pageContext={pageContext}
        title={frontmatter.title}
        description={frontmatter.summary} />
      <article className={`doc-container ${fileName === '_index' ? '_index' : ''}`}>
        <div className="left-container">
          <div className="version-box">
            <ProductVersion pageContext={pageContext} />
            <div className="doc-menu-mobile" role="button" onClick={handleShowDocMenu} onKeyDown={handleShowDocMenu}>
              <Icon type="d_catalog" size={20}/>
            </div>
          </div>
          <DocToc tocNodes={data.toc && data.toc.rawMarkdownBody || ''} pageContext={pageContext} />
        </div>
        {fileName === '_index' ?
          <div className="_index-container">
            {parse(html, options)}
          </div> :
          <>
            <div className="center-container">
              {realSlug && <div className="page-not-found"><div className="text">Note: Relevant language content is not yet available.</div></div>}
              <DocVersionMenu pageContext={pageContext} supportNodes={data.supports.nodes} />
              <div className="markdown-body">
                <div ref={domRef} dangerouslySetInnerHTML={{ __html: html }} />
              </div>
              <DocPageMenu pageContext={pageContext} tocNodes={data.toc && data.toc.rawMarkdownBody || ''} />
            </div>
            <DocDirectoryMenu tableOfContents={tableOfContents} pageContext={pageContext} />
          </>
        }
      </article>
    </Layout>
  )
}
export const pageQuery = graphql`
   query DocTmptQuery($lang: String, $slug: String, $tocPath: String, $fileName: String, $docType: String) {
     docNode: markdownRemark(fields: {slug: {eq: $slug}}) {
       html
       tableOfContents(maxDepth: 3)
       frontmatter {
         title
         summary
       }
     }
 
     toc: markdownRemark(fields: {slug: {eq: $tocPath}}) {
       rawMarkdownBody
     }

     supports: allMarkdownRemark(filter: {fields: {lang: {eq: $lang}, docType: {eq: $docType}, fileName: {eq: $fileName}}}) {
      nodes {
        fields {
          slug
          version
          docType
        }
      }
    }
   }
 `;
export default DocTmptPage
