/* eslint-disable */
import '../../styles/components/toc.scss'

import React, { useRef, useLayoutEffect } from "react"
import { remark } from 'remark'
import html from './remark-html'

// 版本切换
const DocToc = (props) => {
  const { tocNodes, pageContext } = props;
  const { lang, version, docType, slug, realSlug } = pageContext
  const tocRef = useRef(null)
  const tocLinkPrefix = `/${lang}/${docType}/${version}/`
  const _html = remark()
    .use(html, { sanitize: true, tocLinkPrefix, slug: realSlug || slug })
    .processSync(tocNodes)

  const bindClickEventToTOC = () => {
    const toc = tocRef.current.firstChild
    retrieveLi(toc)
  }
  // 绑定点击事件
  function retrieveLi(ul) {
    Array.from(ul.children).forEach(li => {
      const first = li.firstElementChild
      if (!first) return
      if (first.tagName === 'UL' || (first.tagName === 'A' && first.nextElementSibling && first.nextElementSibling.tagName === 'UL')) {
        li.addEventListener('click', clickEvent)
        Array.from(li.children).forEach(retrieveLi)
      }
    })
  }

  // 处理折叠点击事件
  function clickEvent(e) {
    e.stopPropagation()
    if (e.target.tagName === 'A') {
      return false
    }
    const li = e.currentTarget
    // 折叠同层标签
    Array.from(li.parentElement.children).forEach((el) => {
      if (
        el.classList.contains('can-unfold') &&
        !el.classList.contains('folded') &&
        !el.classList.contains('has-no-subject') &&
        li !== el
      ) {
        el.classList.toggle('folded')
        return
      }
    })

    // 记录之前滚动的位置
    const tocColumn = document.querySelector('.toc-container')
    const lastTocScroll = tocColumn.scrollTop

    li.classList.toggle('folded')

    // 当菜单不在可视区内部时，滚动出来
    const top = li.getBoundingClientRect().top
    if (top < 0) {
      if(tocColumn.scrollTo) {
        tocColumn.scrollTo(0, lastTocScroll)
      } else {
        tocColumn.scrollTop = lastTocScroll
      }
    }
  }

  useLayoutEffect(bindClickEventToTOC)

  return (
    <section
      ref={tocRef}
      className="toc-container show"
      dangerouslySetInnerHTML={{ __html: _html }}
    />
  )
}
export default DocToc
