import '../styles/components/syntaxDiagram.scss'
import PropTypes from 'prop-types'
import React from 'react'
import langMap from '../intl'

import Copy from 'copy-to-clipboard';

const SyntaxDiagram = ({ lang, diagramTree, code }) => {
  const [value, setValue] = React.useState(0)

  function copySourceData (e) {
    Copy(code)
    const tipTxt = document.createElement('div');
    tipTxt.style.top = (e.pageY - 30) + 'px';
    tipTxt.style.left = (e.pageX - 20) + 'px';
    tipTxt.classList.add('animate__animated', 'animate__fadeOutUp', 'pre-copied-tip');
    tipTxt.innerHTML = 'Copied';
    tipTxt.addEventListener('animationend', () => {
      document.body.removeChild(tipTxt);
    })
    document.body.appendChild(tipTxt);
  }

  return (
    <div className="syntax-diagram-root">
      <button hidden={value !== 1} onClick={copySourceData} className="source-copy-btn">Copy</button>
      <div className="syntax-diagram-toolbar buttons are-small are-light has-addons is-right">
        <button className={`button is-light ${value === 0 ? 'is-active' : ''}`} onClick={() => setValue(0)}>
          <span>{langMap[lang].doc.diagram}</span>
        </button>
        <button className={`button is-light ${value === 1 ? 'is-active' : ''}`} onClick={() => setValue(1)}>
          <span>{langMap[lang].doc.sourceCode}</span>
        </button>
      </div>
      <div
        hidden={value !== 0}
        className="syntax-diagram-container"
        dangerouslySetInnerHTML={{__html: diagramTree}}
      >
      </div>
      <div hidden={value !== 1} className="source-container">
        <pre className="language-ebnf">
          {code }
        </pre>
      </div>
    </div>
  )
}

SyntaxDiagram.propTypes = {
  diagramTree: PropTypes.string.isRequired,
  code: PropTypes.string.isRequired,
  lang: PropTypes.string.isRequired
}

export default SyntaxDiagram
