/**
* Version
* @description 版本设定，便于设置可上线的文档版本
* latestVersion: 最新版本
* value：真实目录名，用于URL,
* label：用于展示,
* disabled：是否可用
*/
const allProductVersions = {
  mogdb: {
    latestVersion: 'v5.0',
    versions: {
      'v5.0': {
        value: 'v5.0',
        label: 'v5.0',
        disabled: false
      },
      'v3.1': {
        value: 'v3.1',
        label: 'v3.1',
        disabled: false
      },
      'v3.0': {
        value: 'v3.0',
        label: 'v3.0',
        disabled: false
      },
      'v2.1': {
        value: 'v2.1',
        label: 'v2.1',
        disabled: false
      },
      'v2.0': {
        value: 'v2.0',
        label: 'v2.0',
        disabled: false
      },
      'v1.1': {
        value: 'v1.1',
        label: 'v1.1',
        disabled: false
      }
    }
  },
  'mogdb-stack': {
    latestVersion: 'v2.0',
    versions: {
      'v2.0': {
        value: 'v2.0',
        label: 'v2.0',
        disabled: false
      },
      'v1.0': {
        value: 'v1.0',
        label: 'v1.0',
        disabled: false
      }
    }
  },
  mogha: {
    latestVersion: 'v2.4',
    versions: {
      'v2.4': {
        value: 'v2.4',
        label: 'v2.4',
        disabled: false
      }
    }
  },
  ptk: {
    latestVersion: 'v2.0',
    versions: {
      'v2.0': {
        value: 'v2.0',
        label: 'v2.0',
        disabled: false
      },
      'v1.1': {
        value: 'v1.1',
        label: 'v1.1',
        disabled: false
      },
    }
  },
  "zcloud-for-mogdb": {
    latestVersion: 'v6.3',
    versions: {
      'v6.3': {
        value: 'v6.3',
        label: 'v6.3',
        disabled: false
      }
    }
  },
  mtk: {
    latestVersion: 'v2.0',
    versions: {
      'v2.0': {
        value: 'v2.0',
        label: 'v2.0',
        disabled: false
      }
    }
  },
  mdb: {
    latestVersion: 'v2.0',
    versions: {
     'v2.0': {
        value: 'v2.0',
        label: 'v2.0',
        disabled: false
      }
    }
  },
  sca: {
    latestVersion: 'v5.1',
    versions: {
      'v5.1': {
        value: 'v5.1',
        label: 'v5.1',
        disabled: false
      },
      'v5.0': {
          value: 'v5.0',
          label: 'v5.0',
          disabled: false
      },
      'v4.1': {
        value: 'v4.1',
        label: 'v4.1',
        disabled: false
      },
      'v4.0': {
        value: 'v4.0',
        label: 'v4.0',
        disabled: false
      }
    }
  },
  mvd: {
    latestVersion: 'v3.4',
    versions: {
      'v3.4': {
        value: 'v3.4',
        label: 'v3.4',
        disabled: false
      },
      'v2.4': {
        value: 'v2.4',
        label: 'v2.4',
        disabled: false
      },
      'v2.0': {
        value: 'v2.0',
        label: 'v2.0',
        disabled: false
      }
    }
  },
  uqbar: {
    latestVersion: 'v2.0',
    versions: {
      'v2.0': {
        value: 'v2.0',
        label: 'v2.0',
        disabled: false
      },
      'v1.1': {
        value: 'v1.1',
        label: 'v1.1',
        disabled: false
      }
    }
  }
}

module.exports = {
  allProductVersions
}
