import * as React from "react"

import Icon from '../../components/iconfontCpt'

// 文档底部上一页下一页切换菜单
const DocPageMenu = ({ tocNodes, pageContext }) => {
  const { lang, version, docType, realSlug, slug } = pageContext || {}

  const pageMenuArr = []

  const tocList = []
  tocNodes.replace(/([^/(]*)\.md\)/g, (_, name) => {
    tocList.push(name)
  })

  const urlPrefix = `/${lang}/${docType}/${version}/`

  const pageSlug = realSlug || slug
  const activeIdx = tocList.findIndex(i => urlPrefix + i === pageSlug)
  
  if (activeIdx > 0) {
    pageMenuArr.push({
      key: 'prev',
      class: 'menu icon-btn prev',
      en: 'PREV',
      zh: '上一页',
      path: urlPrefix + tocList[activeIdx - 1]
    })
    pageMenuArr.push({
      class: 'menu',
      en: 'HOME',
      zh: '首页',
      path: urlPrefix + 'overview'
    })
  }
  if (activeIdx < tocList.length - 1) {
    pageMenuArr.push({
      key: 'next',
      class: 'menu icon-btn next',
      en: 'NEXT',
      zh: '下一页',
      path: urlPrefix + tocList[activeIdx + 1]
    })
  }
  return (
    <div className="doc-page-menu">
      <div className="menu-content">
        {pageMenuArr.map((i, idx) => 
          <a key={idx} className={i.class} href={i.path}>
            {i.key === 'prev' && <Icon customClass="arraw-icon prev" type="d_line_arraw_left" size={10}/>}
            <span>{i[lang]}</span>
            {i.key === 'next' && <Icon customClass="arraw-icon" type="d_line_arraw_left" size={10}/>}
          </a>
        )}
      </div>
    </div>
  )
}
export default DocPageMenu
