import * as React from "react"

const CustomContainer = ({ title, subTitle, children, pageContext }) => {
  const { lang } = pageContext
  return (
    <>
      <div className={`title-box ${lang}`}>
        <h1 className="product-title">{title}</h1>
        <p className="product-desc">{subTitle}</p>
      </div>
      <div className="index-box">{ children }</div>
    </>
  )
}
export default CustomContainer
